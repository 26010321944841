import uniqBy from "lodash/uniqBy"
import React from "react"
import { connect } from "react-redux"
import { Control, Form as ReduxForm, actions } from "react-redux-form"
import { Link } from "gatsby"
import Papa from "papaparse"
import JSZip from "jszip"
import { Form, Button, Col } from "react-bootstrap"

import { expectAuthenticationState } from "../../../components/authentication"

import Piwik from "../../../config/piwik"
import ENVIRONMENT from "../../../config/environment"
import Loading from "../../../components/loading"
import {
  ErrorList,
  validateMachineId,
  validateNoSpacesInComputerName,
} from "../../../components/validation"
import { downloadFile } from "../../../utils/download"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import ErrorApi from "../../../components/error"
import SuccessApi from "../../../components/success"

import getLicenseFileName from "../../../../../backend/src/licenses/license-file-name"

const RequestLicensePage = () => (
  <Layout>
    <SEO title="Request multiple licenses" />
    <h1 data-test="page-title">Request multiple licenses</h1>
    <ConnectedRequestLicenseForm />
  </Layout>
)
export default expectAuthenticationState({ connected: true, onboarded: true })(RequestLicensePage)

class RequestLicenseForm extends React.Component {
  usageCount

  constructor() {
    super()
    this.usageCount = 0
    this.state = {
      error: false,
      errorMessage: null,
      requestInProgress: false,
      successful: false,
    }
  }

  render() {
    const { error, errorMessage, requestInProgress, successful } = this.state
    return (
      <>
        {error && <ErrorApi>{errorMessage}</ErrorApi>}

        {successful && (
          <SuccessApi>
            We have sent you the license details at {this.props.email}.<p />
            <Link to="/my-licenses">Click here to view your licenses</Link>
          </SuccessApi>
        )}
        <ReduxForm
          model="requestMultipleLicensesForm.data"
          onSubmit={values => this.handleSubmit(values)}
          onChange={values => this.handleChange(values)}
        >
          <Form.Row>
            <Col md={10} lg={8} xl={6}>
              <Form.Group>
                <Form.Label htmlFor="computerNameField" className="is-required">
                  Enter license requests
                </Form.Label>
                <Control.textarea
                  className="form-control"
                  model=".licensesCommand"
                  id="computerNameField"
                  rows={10}
                  placeholder={
                    "computer_name_1;machine_id_1\ncomputer_name_2;machine_id_2"
                  }
                  validators={{
                    required: val => val && val.length,
                    parsable: val => parseLicences(val).ok === true,
                  }}
                  data-test="licenses-command"
                />
                <ErrorList
                  model=".licensesCommand"
                  messages={{
                    required: "Required",
                    parsable: val => {
                      const parsed = parseLicences(val)
                      return (
                        <span>{parsed.ok === false ? parsed.error : ""}</span>
                      )
                    },
                  }}
                />
                <Form.Text className="text-muted">
                  <p>
                    <strong>Pattern:</strong>
                    {'"computer_name;machine_id"'}, one license request per
                    line.
                  </p>
                  <p>
                    Please read the{" "}
                    <Link to="/installation-notes-v10">installation notes</Link>{" "}
                    in order to find your {'"Computer name" and "Machine ID".'}
                  </p>
                </Form.Text>
                <SuccessListConnected className="mt-3" />
              </Form.Group>

              <Form.Group>
                <Form.Label
                  htmlFor="khiopsVersionSelect"
                  className="is-required"
                >
                  Khiops version
                </Form.Label>
                <Control.select
                  type="field"
                  className="custom-select mr-sm-2"
                  model=".khiopsVersion"
                  id="khiopsVersionSelect"
                  data-test="khiops-version"
                >
                  <option key="10" value="10">
                    Khiops V10
                  </option>
                  <option key="9" value="9">
                    Khiops V9
                  </option>
                  <option key="8" value="8">
                    Khiops V8
                  </option>
                </Control.select>
              </Form.Group>

              {!requestInProgress ? (
                <>
                  <Button
                    type="button"
                    variant="secondary"
                    className="mr-2"
                    as={Link}
                    to="/my-licenses/request"
                    data-test="cancel"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" data-test="submit">
                    Request your licenses
                  </Button>
                </>
              ) : (
                <Loading />
              )}
            </Col>
          </Form.Row>
        </ReduxForm>
      </>
    )
  }

  handleChange() {
    this.setState({ successful: false })
    this.usageCount++
    Piwik.push([
      "trackEvent",
      "RequestMultipleLicense",
      "RequestMultipleLicense_FillingForm",
    ])
    if (this.usageCount === 10) {
      Piwik.push([
        "trackEvent",
        "RequestMultipleLicense",
        "RequestMultipleLicense_FillingForm_moreThan10Chars",
      ])
    }
  }

  async handleSubmit({ licensesCommand, khiopsVersion }) {
    if (ENVIRONMENT.name === "ssr") {
      return // no submit in SSR
    }

    Piwik.push([
      "trackEvent",
      "RequestMultipleLicense",
      "RequestMultipleLicense_Submit",
    ])

    this.setState({
      requestInProgress: true,
      successful: false,
    })
    const licenses = parseLicences(licensesCommand)
    if (!licenses.ok) {
      throw new Error("Licenses not parsable")
    }

    const request = {
      licenses: licenses.licenses,
      khiopsVersion: khiopsVersion,
    }

    const response = await fetch("/api/my-licenses/request/multiple", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify(request),
    })
    if (response.ok) {
      this.setState(
        { error: false, requestInProgress: false, errorMessage: null },
        async () => {
          const result = await response.json()
          if (result.status === "ok") {
            this.setState({ error: false, successful: true })

            if (!window.Cypress) {
              await downloadZipWithLicenses(result.licenses)
            }
          } else {
            if (result.error === "INVALID_MACHINE_ID") {
              this.setState({
                error: true,
                errorMessage: `The provided Machine ID "${result.computerId}" does not match the provided Computer name "${result.computerName}"`,
              })
            } else {
              this.setState({ error: true, errorMessage: null })
            }
          }
        },
      )
    } else {
      this.setState({ error: true, requestInProgress: false })
    }
  }

  componentWillUnmount() {
    this.props.resetModel("requestMultipleLicensesForm.data")
  }
}

function mapStateToProps(state) {
  return {
    email: state.user.email,
  }
}

const ConnectedRequestLicenseForm = connect(mapStateToProps, dispatch => ({
  resetModel: model => dispatch(actions.reset(model)),
}))(RequestLicenseForm)

function SuccessList({ licensesCommand }) {
  const parsed = parseLicences(licensesCommand)
  if (!parsed.ok) {
    return null
  }
  return (
    <SuccessApi>
      {parsed.licenses.length} license(s) to request. Computer names :{" "}
      {parsed.licenses.map(({ computerName }) => computerName).join(", ")}.
    </SuccessApi>
  )
}

const SuccessListConnected = connect(
  ({
    requestMultipleLicensesForm: {
      data: { licensesCommand },
    },
  }) => ({
    licensesCommand,
  }),
)(SuccessList)

function parseLicences(request) {
  const result = Papa.parse(request.trim())
  if (result.errors.length > 0) {
    return {
      ok: false,
      error:
        'Invalid pattern, please follow the "computer_name;machine_id" pattern, one license request per line.',
    }
  }

  const licenses = uniqBy(
    result.data.map(([computerName, computerId]) => ({
      computerName,
      computerId,
    })),
    ({ computerName, computerId }) => `${computerName}/${computerId}`,
  )

  for (const { computerName, computerId } of licenses) {
    if (!validateNoSpacesInComputerName(computerName)) {
      return {
        ok: false,
        error: `Invalid computer name: "${computerName}". The computer name must not include spaces`,
      }
    }

    if (!validateMachineId(computerId)) {
      return {
        ok: false,
        error: `Invalid machine ID: "${computerId}".`,
      }
    }
  }

  if (licenses.length > 100) {
    return {
      ok: false,
      error: `At most 100 licenses can be requested`,
    }
  }

  return {
    ok: true,
    licenses,
  }
}

async function downloadZipWithLicenses(licenses) {
  const zip = new JSZip()
  licenses.forEach(license =>
    zip.file(getLicenseFileName(license), license.licenseFile),
  )
  const blob = await zip.generateAsync({
    type: "blob",
    compression: "DEFLATE",
    compressionOptions: {
      level: 9,
    },
  })
  downloadFile("licenses.zip", blob)
}
